import Vuex from "vuex";

export default new Vuex.Store({
  state: {
    user: {
      loggedIn: false,
      admin: false,
      accessToken: '',
      data: null
    }
  },
  getters: {
    user(state){
      return state.user
    },
    isLoggedIn(state){
      return state.user.loggedIn
    },
    getAccessToken: state => {
      return state.accessToken
    }
  },
  mutations: {
    SET_LOGGED_IN(state, value) {
      state.user.loggedIn = value;
    },
    SET_USER(state, data) {
      state.user.data = data;
    },
    SET_STORE_TOKEN(state, accessToken) {
      state.accessToken = accessToken
    },
    SET_USER_ADMIN(state, value) {
      state.user.admin = value
    },
  },
  actions: {
    fetchUser({ commit }, user) {
      commit("SET_LOGGED_IN", user !== null);
      if (user) {
        commit("SET_USER", {
          displayName: user.displayName,
          email: user.email
        });
      } else {
        commit("SET_USER", null);
      }
    }
  }
});
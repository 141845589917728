import axios from 'axios';
import store from '../store'

export const makeUserAdmin = async (email) => {
  const token = store.getters.getAccessToken;

  return axios(
    {
      method: 'post',
      url: `${process.env.VUE_APP_ENDPOINT}/api/auth/makeadmin`,
      headers: {
        'authorization': `Bearer ${token}`
      },
      data: {
        email: email
      }
    }
  )
}
export const removeAdmin = async (email) => {
  const token = store.getters.getAccessToken;

  return axios(
    {
      method: 'post',
      url: `${process.env.VUE_APP_ENDPOINT}/api/auth/removeadmin`,
      headers: {
        'authorization': `Bearer ${token}`
      },
      data: {
        email: email
      }
    }
  )
}
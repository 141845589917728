<template>
    <div class="row mb-5">
      <h1>Add Alice comment</h1>
        <form @submit.prevent="addComment">
          <div class="row">

            <div class="col-md">
              <div class="form-outline">
                  <input type="text" v-model="comment.username" id="commentUsername" class="form-control" required />
                  <label class="form-label" for="commentUsername">Username</label>
              </div>
            </div>

            <div class="col-md">
              <div class="form-outline">
                  <input type="text" v-model="comment.message" id="commentMessage" class="form-control" required />
                  <label class="form-label" for="commentMessage">Messagge</label>
              </div>
            </div>

          </div>
          <button type="submit" class="btn btn-primary btn-block">Add comment</button>
          <p class="mt-3" :class="{ 'text-danger': error, 'text-success': !error }">{{ response }}</p>  
      </form>
    </div>
</template>

<script>
import { addAliceComment } from '../../modules/alice'

export default {
  data() {
    return {
      comment: {
        username: '',
        message: ''
      },
      response: '',
      error: false
    };
  },
  methods: {
    addComment(){
      addAliceComment(this.comment.username, this.comment.message)
        .then(res => { 
          console.log(res)
          this.error = false
          this.response = 'Comment added'
          this.comment.username = ''
          this.comment.message = ''
        })
        .catch(res => {
          console.log(res)
          this.error = true
          this.response = "Error adding comment"
        })
    },
  }
};
</script>
<template>
  <div class="container" v-if="!user.admin">
    <h1>You are not an admin</h1>
    <p>Ask an admin to make you admin too</p>
  </div>
  <div class="container text-center" v-else>
    <h1>Hi <i>{{ user.data.email }}</i>! </h1>
    <p class="text-secondary">Please select an action in the menu </p>
  </div>
</template>


<script>
import { mapGetters } from "vuex";

export default {
  computed: {
    ...mapGetters({
      user: "user"
    }),
  }
};
</script>
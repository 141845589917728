<template>
    <div class="row mb-5">
      <h1>Notifications subscribers</h1>
      <div v-if="loading">
        Loading...
      </div>
      <div v-else>
        <p>There are:</p>
        <ul class="ps-5">
          <li>{{ subscribers.tokensIt.length }} italians subscribers</li>
          <li>{{ subscribers.tokensEn.length }} english subscribers</li>
        </ul> 
      </div>
    </div>
</template>

<script>
import { getNotificationSubscribers } from '../../modules/notifications';

export default {
  data() {
    return {
      subscribers: {
        tokensIt: [],
        tokensEn: []
      },
      loading: true
    };
  },
  methods: {
    getSubscribers() {
      getNotificationSubscribers().then(subscribers => {
        this.subscribers = subscribers;
        this.loading = false;
      });
    }
  },
  beforeMount() {
    this.getSubscribers();
  }
};
</script>
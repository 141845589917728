<template>
    <div class="row mb-5">
      <h1>Edit an Alice comment</h1>
        <form class="mb-3">
          <label class="form-label" for="commentsSelect">Select a comment</label>
          <select class="form-select" name="commentsSelect" v-model="selectedComment" @change="getComment(this.selectedComment)">
            <option v-for="comment in comments" :value="comment.id" :key="comment.id">{{ comment.id }} - {{ comment.message }}</option>
          </select>
        </form>

        <form @submit.prevent="editComment">
          <div class="row">

            <div class="col-md">
              <div class="form-outline">
                  <input type="text" v-model="comment.username" id="commentUsername" class="form-control" required />
                  <label class="form-label" for="commentUsername">Username</label>
              </div>
            </div>

            <div class="col-md">
              <div class="form-outline">
                  <input type="text" v-model="comment.message" id="commentMessage" class="form-control" required />
                  <label class="form-label" for="commentMessage">Messagge</label>
              </div>
            </div>

          </div>
          <button type="submit" class="btn btn-primary btn-block">Edit comment</button>
          <p class="mt-3" :class="{ 'text-danger': error, 'text-success': !error }">{{ response }}</p>  
      </form>
    </div>
</template>

<script>
import { editAliceComment } from '../../modules/alice'
import { readAliceComments, getAliceComment } from '../../modules/db'

export default {
  data() {
    return {
      selectedComment: '',
      comments: [],
      comment: {
        id: '',
        timestamp: '',
        username: '',
        message: ''
      },
      response: '',
      error: false
    };
  },
  methods: {
    editComment(){
      editAliceComment(this.comment.id, this.comment.username, this.comment.message)
        .then(res => { 
          console.log(res)
          this.error = false
          this.$toast.success('Comment edited successfully');
          this.response = 'Comment updated'
          this.comment.username = ''
          this.comment.id = ''
          this.comment.message = ''
          this.selectedComment = ''
        })
        .catch(res => {
          console.log(res)
          this.error = true
          this.response = res.message
          this.$toast.error('Error updating comment');
        })
    },
    getComments(){
      // fetch comments from firebase db
      readAliceComments(comments => {
        comments = comments.val()
        this.comments = Object.keys(comments).map(key => {
          return Object.assign(comments[key], { id: key })
        })
        console.log(this.comments)
      })
    },
    getComment(id){
      getAliceComment(id, comment => {
        comment = comment.val()
        
        this.comment = {
          id: id,
          message: comment.message,
          username: comment.username,
          timestamp: comment.timestamp
        }
      })
    }
  },
  beforeMount() {
    this.getComments()
  },
};
</script>
<template>
  <div class="card bg-dark sidebar border-light ms-lg-4 mb-3 mb-md-0 me-lg-4">
      <button class="btn btn-toggle link-light align-items-center rounded collapsed" id="sidebar-button" data-bs-toggle="collapse" data-bs-target="#sidebar-collapse" aria-expanded="false">
        _
      </button>
      <div class="collapse" id="sidebar-collapse">
        <ul class="list-unstyled ps-0 mt-3">
          <!-- comments -->
          <li class="mb-1 ps-3">
            <button class="btn btn-toggle link-light align-items-center rounded collapsed" data-bs-toggle="collapse" data-bs-target="#comments-collapse" aria-expanded="false">
              Comments
            </button>
            <div class="collapse" id="comments-collapse">
              <ul class="btn-toggle-nav list-unstyled fw-normal pb-1 small">
                <li><router-link :to="{ name: 'Add comment' }">Add comment</router-link></li>
                <li><router-link :to="{ name: 'Edit comment' }">Edit comment</router-link></li>
                <li><router-link :to="{ name: 'Remove comment' }">Remove comments</router-link></li>
              </ul>
            </div>
          </li>
          <!-- admins -->
          <li class="mb-1 ps-3">
            <button class="btn btn-toggle link-light align-items-center rounded collapsed" data-bs-toggle="collapse" data-bs-target="#admins-collapse" aria-expanded="false">
              Manage admins
            </button>
            <div class="collapse" id="admins-collapse" style="">
              <ul class="btn-toggle-nav list-unstyled fw-normal pb-1 small">
                <li><router-link :to="{ name:'Add admin' }" >Add admin</router-link></li>
                <li><router-link :to="{ name:'Remove admin' }" >Remove admin</router-link></li>
              </ul>
            </div>
          </li>
          <!-- notifications -->
          <li class="mb-1 ps-3">
            <button class="btn btn-toggle link-light align-items-center rounded collapsed" data-bs-toggle="collapse" data-bs-target="#notifications-collapse" aria-expanded="false">
              Notifications
            </button>
            <div class="collapse" id="notifications-collapse" style="">
              <ul class="btn-toggle-nav list-unstyled fw-normal pb-1 small">
                <li><router-link  :to="{ name:'Send notification' }" >Send notification</router-link></li>
                <li><router-link  :to="{ name:'See subscribers' }" >See subscribers</router-link></li>
              </ul>
            </div>
          </li>
          <li class="mb-1 ps-4 list-unstyled small">
            <router-link :to="{name: 'Set alice config'}" class="text-decoration-none">Set alice config</router-link>
          </li>
          <!-- <li class="border-top my-3"></li> -->
          <!-- account -->
          <!-- <li class="mb-1 ps-3">
            <button class="btn btn-toggle link-light align-items-center rounded collapsed" data-bs-toggle="collapse" data-bs-target="#account-collapse" aria-expanded="false">
              Account
            </button>
            <div class="collapse" id="account-collapse">
              <ul class="btn-toggle-nav list-unstyled fw-normal pb-1 small">
                <li><a href="#" class="rounded">New...</a></li>
                <li><a href="#" class="rounded">Profile</a></li>
                <li><a href="#" class="rounded">Settings</a></li>
                <li><a href="#" class="rounded">Sign out</a></li>
              </ul>
            </div>
          </li> -->
        </ul>
      </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { Collapse } from "bootstrap";

export default {
  computed: {
    ...mapGetters({
      // map `this.user` to `this.$store.getters.user`
      user: "user",
    }),
  },
  methods: {
  },
  watch:{
    $route (){
      const myCollapse = document.getElementById('sidebar-collapse');
      if (myCollapse) {
        const bsCollapse = new Collapse(myCollapse, {
          toggle: false
        }); 
        bsCollapse.hide();
      }
    }
  }
};
</script>

<style lang="postcss" scoped>

  @media (min-width: 992px) {
    .sidebar{
      width: 280px;
      height: 100%;
    }
    #sidebar-collapse{
      display: block !important;
    }
    #sidebar-button{
      display: none !important;
    }
  }

  .sidebar{
    top: 10px;
    position: -webkit-sticky; 
    position: sticky;
  }

  .dropdown-toggle { outline: 0; }

  .nav-flush .nav-link {
    border-radius: 0;
  }

  .btn-toggle {
    display: inline-flex;
    align-items: center;
    padding: .25rem .5rem;
    font-weight: 600;
    border: 0;
  }

  .btn-toggle::before {
    width: 1.25em;
    line-height: 0;
    content: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='rgba%28248,249,250,1%29' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M5 14l6-6-6-6'/%3e%3c/svg%3e");
    transition: transform .35s ease;
    transform-origin: .5em 50%;
  }

  .btn-toggle[aria-expanded="true"]::before {
    transform: rotate(90deg);
  }

  .btn-toggle-nav a {
    display: inline-flex;
    padding: .1875rem .5rem;
    margin-top: .125rem;
    margin-left: 1.25rem;
    text-decoration: none;
  }

  .scrollarea {
    overflow-y: auto;
  }

  .fw-semibold { font-weight: 600; }
  .lh-tight { line-height: 1.25; }
</style>

<template>
  <div class="bg-dark text-light min-vh-100">
    <navbar></navbar>
    <main class="container-fluid d-flex flex-column justify-content-between flex-lg-row">
      <sidebar v-if="user.admin"></sidebar>
      <div class="container">
        <router-view></router-view>
      </div>
    </main>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import navbar from "./components/Navbar";
import sidebar from "./components/Sidebar";

export default {
  computed: {
    ...mapGetters({
      user: "user"
    }),
  },
  components: {
    navbar,
    sidebar
  }
};
</script>


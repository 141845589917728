import { db } from "../services/firebase-service";

export const readAliceConfig = function(callback) {
  db()
    .ref("alice/config")
    .on("value", callback);
};

// reads all the alice comments
export const readAliceComments = function(callback) {
  db()
    .ref("alice/comments")
    .on("value", callback);
};

// reads an alice comment given the id
export const getAliceComment = function(id, callback) {
  db()
    .ref(`alice/comments/${id}`)
    .on("value", callback);
};
<template>
    <div class="row mb-5">
      <div class="col-md">
        <h2>Remove admin</h2>
        <form @submit.prevent="removeAdmin">
          <div class="form-outline mb-4">
            <input type="email" id="email" v-model="email" class="form-control" required />
            <label class="form-label" for="email">Email address</label>
          </div>
          <button type="submit" class="btn btn-primary btn-block">Remove admin</button>
        </form>
        <p class="mt-3" :class="{ 'text-danger': error, 'text-success': !error }">{{ response }}</p>  
      </div>
    </div>
</template>

<script>
import { removeAdmin } from '../../services/auth-service'

export default {
  data() {
    return {
      email: "",
      response: "",
      error: false
    };
  },
  methods: {
    removeAdmin() {
      removeAdmin(this.email)
        .then(res => {
          console.log(res)
          this.error = false
          this.response = res.data.msg 
        })
        .catch(err => {
          console.log(err)
          this.error = true
          this.response = "An error occurred"
        })
    },
  }
};
</script>
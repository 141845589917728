<template>
    <div class="row mb-5">
        <h1>Set Alice config</h1>
        <form @submit.prevent="setAliceConfig">
            <div class="row">

            <div class="col-md">
                <div class="form-outline">
                    <select class="form-select " v-model="newConfig.bikeName" id="bikeName" required aria-label="Bike name">
                    <option value="taurus">Taurus</option>
                    <option value="taurusx">TaurusX</option>
                    <option value="phoenix">Phoenix</option>
                    </select>
                    <label class="form-label" for="bikeName">bikeName</label>
                </div>
            </div>

            <div class="col-md">
                <div class="form-outline">
                    <input type="date" v-model="newConfig.date" id="aliceDate" class="form-control" required />
                    <label class="form-label" for="aliceDate">date</label>
                </div>
            </div>

            </div>
            <div class="row">

            <div class="col-md">
                <div class="form-outline">
                <input type="time" step="2" v-model="newConfig.startTime" id="startTime" class="form-control" placeholder="hh:mm:ss" required />
                <label class="form-label" for="startTime">startTime</label>
                </div>
            </div>

            <div class="col-md">
                <div class="form-outline">
                <select class="form-select " v-model="newConfig.trackName" id="trackName" required aria-label="Track name">
                    <option value="bm">Battle Mountain</option>
                    <option value="balocco">Balocco</option>
                    <option value="orbassano">Orbassano</option>
                </select>
                <label class="form-label" for="trackName">trackName</label>
                </div>
            </div>

            <div class="d-md-block">
                <button type="submit" class="btn btn-primary ">Send Alice config</button>
                <p>{{ response }}</p>
            </div>

            <div class="d-md-block">
                <p>Current Alice config:</p>
                <code>{{ currentAliceConfig }}</code>
            </div>
            </div>
        </form>
    </div>
</template>

<script>
import { readAliceConfig } from '../../modules/db'
import { setAliceConfig } from '../../modules/alice'

export default {
  data() {
    return {
      newConfig: {
        bikeName: '',
        date: '',
        startTime: '',
        trackName: ''
      },
      currentAliceConfig: {
      },
      response: '',
    };
  },
  methods: {
    setAliceConfig(){
      let valid = true
      // TODO: check config format
      if (valid)
        setAliceConfig(this.newConfig)
          .then(res => { 
            this.response = 'Server response: ' + JSON.stringify(res.data)
          })
          .catch(res => {
            console.log(res)
            this.response = "Error setting configs"
          })
      else
        this.response = "Data format not valid"
    },
    readAliceConfig(){
      readAliceConfig(newValue => {
        newValue = newValue.val()
        this.newConfig.bikeName = newValue.bikeName
        this.newConfig.date = newValue.date
        this.newConfig.startTime = newValue.startTime
        this.newConfig.trackName = newValue.trackName
        this.currentAliceConfig = newValue
      })
    }
  },
  beforeMount(){
    this.readAliceConfig()
  },
}
</script>
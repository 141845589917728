<template>
  <div class="container-fluid border-bottom mb-4 rounded-bottom">
    <header class="d-flex flex-wrap justify-content-center py-3">
      <router-link
        to="/"
        class="
          d-flex
          align-items-center
          mb-0
          me-auto
          text-decoration-none 
          fs-4
          text-white
        "
        >Pino &#127794;&#10084;&#65039;</router-link
      >
      <div class="text-end">
        <template v-if="user.loggedIn">
          <span class="px-2 px-md-4 d-none d-md-inline">{{ user.data.email }}</span>
          <button type="button" @click.prevent="signOut" class="btn btn-outline-light me-2">Sign out</button>
        </template>
        <template v-else>
          <router-link :to="{ name: 'Login' }" >
            <button type="button" class="btn btn-outline-light me-2">Login</button>
          </router-link>
          <router-link :to="{ name: 'Register' }">
            <button type="button" class="btn btn-warning">Sign-up</button>
          </router-link>
        </template>
      </div>
    </header>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import { auth } from "../services/firebase-service";

export default {
  computed: {
    ...mapGetters({
      // map `this.user` to `this.$store.getters.user`
      user: "user",
    }),
  },
  methods: {
    signOut() {
      auth()
        .signOut()
        .then(() => {
          this.$router.replace({
            name: "Login",
          });
        });
    },
  },
};
</script>

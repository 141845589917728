import store from "../store";
import axios from "axios";

export const sendNotification = async function(titleIt, titleEn, messageIt, messageEn){
  const token = store.getters.getAccessToken;

  const data = {
    titleIt: titleIt,
    titleEn: titleEn,
    messageIt: messageIt,
    messageEn: messageEn
  }

  return axios.post(`${process.env.VUE_APP_ENDPOINT}/api/alice/push_notification`, 
   data, {
     headers: { authorization: `Bearer ${token}` },
   } );
};

// get notifications subscribers
export const getNotificationSubscribers = async function(){
  const token = store.getters.getAccessToken;

  return axios.get(`${process.env.VUE_APP_ENDPOINT}/api/alice/notifications/subscribers`, {
     headers: { authorization: `Bearer ${token}` },
   } ).then(response => {
      return response.data;
   });
};

<template>
    <div class="row mb-5">
      <h1>Send notifications</h1>
        <form @submit.prevent="sendNotification">
          <div class="row">

            <div class="col-md">
              <div class="form-outline">
                  <input type="text" v-model="titleIt" id="titleIt" class="form-control" required />
                  <label class="form-label" for="titleIt">Notification title [IT]</label>
              </div>
            </div>

            <div class="col-md">
              <div class="form-outline">
                  <input type="text" v-model="titleEn" id="titleEn" class="form-control" required />
                  <label class="form-label" for="titleEn">Notification title [EN]</label>
              </div>
            </div>

          </div>

          <div class="row">

            <div class="col-md">
              <div class="form-outline">
                <input type="text" v-model="messageIt" id="messageIt" class="form-control" required />
                <label class="form-label" for="messageIt">Message [IT]</label>
              </div>
            </div>

            <div class="col-md">
              <div class="form-outline">
                <input type="text" v-model="messageEn" id="messageEn" class="form-control" required />
                <label class="form-label" for="messageEn">Message [EN]</label>
              </div>
            </div>

            <div class="d-md-block">
              <button type="submit" class="btn btn-primary ">Send notification</button>
              <p>{{ response }}</p>
            </div>
          </div>
        </form>
    </div>
</template>

<script>
import { sendNotification } from '../../modules/notifications'

export default {
  data() {
    return {
      titleEn: "",
      titleIt: "",
      messageEn: "",
      messageIt: "",
      response: "",
    };
  },
  methods: {
    sendNotification(){
      sendNotification(this.titleIt, this.titleEn, this.messageIt, this.messageEn)
        .then(res => { 
          this.response = res.data
        })
        .catch(res => {
          console.log(res)
          this.response = "Error sending notification"
        })
    },
  }
};
</script>
import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/database';

var firebaseConfig = {
    apiKey: "AIzaSyDe2cTCJYOygSp2SgyhNtd0riAlC64_8pU",
    authDomain: "policumbent-2021.firebaseapp.com",
    databaseURL: "https://policumbent-2021-default-rtdb.firebaseio.com",
    projectId: "policumbent-2021",
    storageBucket: "policumbent-2021.appspot.com",
    messagingSenderId: "1035121678665",
    appId: "1:1035121678665:web:dab901d6f0103910bc6298",
    measurementId: "G-X40X74LN0T"
}

// Initialize Firebase
firebase.initializeApp(firebaseConfig)

export const auth = firebase.auth
export const db = firebase.database

export default firebase;
import { createRouter, createWebHistory } from "vue-router";
import Login from "../components/Login";
import Register from "../components/Register";
import Home from "../components/Home";
import SendNotification from "../components/actions/SendNotifications.vue";
import NotificationsSubscribers from "../components/actions/NotificationsSubscribers.vue";
import AddAdmin from "../components/actions/AddAdmin.vue";
import RemoveAdmin from "../components/actions/RemoveAdmin.vue";
import AddComment from "../components/actions/AddComment.vue";
import EditComment from "../components/actions/EditComment.vue";
import RemoveComments from "../components/actions/RemoveComments.vue";
import SetAliceConfig from "../components/actions/SetAliceConfig.vue";
import store from '../store'

const history = createWebHistory();

const router = new createRouter({
  mode: "history",
  history,
  base: process.env.BASE_URL,
  routes: [
    {
      path: "/login",
      name: "Login",
      component: Login,
      meta: {
        hideForAuth: true
      }
    },
    {
      path: "/register",
      name: "Register",
      component: Register,
      meta: {
        hideForAuth: true
      }
    },
    {
      path: "/",
      name: "Home",
      component: Home,
      meta: {
        requiresAuth: true
      }
    },
    {
      path: "/notifications/send",
      name: "Send notification",
      component: SendNotification,
      meta: {
        requiresAuth: true
      }
    },
    {
      path: "/notifications/subscribers",
      name: "See subscribers",
      component: NotificationsSubscribers,
      meta: {
        requiresAuth: true
      }
    },
    {
      path: "/admins/add",
      name: "Add admin",
      component: AddAdmin,
      meta: {
        requiresAuth: true
      }
    },
    {
      path: "/admins/remove",
      name: "Remove admin",
      component: RemoveAdmin,
      meta: {
        requiresAuth: true
      }
    },
    {
      path: "/comments/add",
      name: "Add comment",
      component: AddComment,
      meta: {
        requiresAuth: true
      }
    },
    {
      path: "/comments/edit",
      name: "Edit comment",
      component: EditComment, 
      meta: {
        requiresAuth: true
      }
    },
    {
      path: "/comments/remove",
      name: "Remove comment",
      component: RemoveComments, 
      meta: {
        requiresAuth: true
      }
    },
    {
      path: "/alice/config/set",
      name: "Set alice config",
      component: SetAliceConfig, // TODO change to 
      meta: {
        requiresAuth: true
      }
    },
  ],
});

router.beforeEach((to, from, next) => {
    if (to.matched.some(record => record.meta.requiresAuth)) {
      // this route requires auth, check if logged in
      // if not, redirect to login page.
      if (!store.getters.isLoggedIn) {
        next({ 
          name: 'Login',
          params: { nextUrl: to.fullPath }
        })
      } else {
        next()
      }
    } else if (to.matched.some(record => record.meta.hideForAuth)) {
      // this route is hidden for authenticated users
      if (store.getters.isLoggedIn) {
        next({ name: 'Home' })
      } else {
        next() 
      }
    } else {
      next()
    }
  })

export default router;

import { createApp } from 'vue'
import App from './App.vue'
import store from './store'
import router from './router/index'
import { auth } from './services/firebase-service'
import Toaster from "@meforma/vue-toaster";

// Import Bootstrap an BootstrapVue CSS files (order is important)
import "bootstrap/dist/css/bootstrap.min.css"
import "bootstrap"

let app;

auth().onAuthStateChanged(user => {
    
  if (!app)
    app = createApp(App)
    .use(store)
    .use(router)
    .use(Toaster)
    .mount('#app')

  if (user){
    user.getIdToken().then(function(idToken) {
      store.commit('SET_STORE_TOKEN', idToken)
      return idToken
    });
    
    auth()
      .currentUser.getIdTokenResult()
      .then(tokenResult => {
          store.commit('SET_USER_ADMIN', tokenResult.claims.admin)
      });
  }
  else
    store.commit('SET_USER_ADMIN', false)

  store.dispatch("fetchUser", user);
});



import store from "../store";
import axios from "axios";

export const setAliceConfig = async function(newConfig){
  const token = store.getters.getAccessToken;

  return axios.post(`${process.env.VUE_APP_ENDPOINT}/api/alice/config`, 
   newConfig, {
     headers: { authorization: `Bearer ${token}` },
   } );
};

export const addAliceComment = async function(username, message){
  const token = store.getters.getAccessToken;

  const data = {
      "comments": [
      {
        username: username,
        message: message
      }
    ]
  }

  return axios.put(`${process.env.VUE_APP_ENDPOINT}/api/alice/comments`, 
    data, {
     headers: { authorization: `Bearer ${token}` },
   } );
};

export const editAliceComment = async function(id, username, message){
  const token = store.getters.getAccessToken;

  const data = {
      "comment": {
        username: username,
        message: message
      }
  }

  return axios.put(`${process.env.VUE_APP_ENDPOINT}/api/alice/comments/${id}`, 
    data, {
     headers: { authorization: `Bearer ${token}` },
   } );
};

export const removeAliceComment = async function(id){
  const token = store.getters.getAccessToken;

  return axios.delete(`${process.env.VUE_APP_ENDPOINT}/api/alice/comments/${id}`, {
     headers: { authorization: `Bearer ${token}` },
   } );
};

export const removeAllAliceComments = async function(){
  const token = store.getters.getAccessToken;

  return axios.delete(`${process.env.VUE_APP_ENDPOINT}/api/alice/comments`, {
     headers: { authorization: `Bearer ${token}` },
   } );
};


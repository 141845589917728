<template>
    <div class="row mb-5">
      <h1>Remove Alice comments</h1>
        <form class="mb-3" @submit.prevent="removeComment">
          <label class="form-label" for="commentsSelect">Select a comment</label>
          <select class="form-select mb-3" name="commentsSelect" v-model="selectedComment" @change="getComment(this.selectedComment)" required>
            <option v-for="comment in comments" :value="comment.id" :key="comment.id">{{ comment.id }} - {{ comment.message }}</option>
          </select>
          <button type="submit" class="btn btn-primary btn-block" :disabled="selectedComment === null">Remove comment</button>      
        </form>
        <p>or</p>
        <form @submit.prevent="removeAllComments">
          <button type="submit" class="btn btn-primary btn-block">Remove all comments</button>      
        </form>
        <p class="mt-3" :class="{ 'text-danger': error, 'text-success': !error }">{{ response }}</p>  
    </div>
</template>

<script>
import { removeAliceComment, removeAllAliceComments } from '../../modules/alice'
import { readAliceComments, getAliceComment } from '../../modules/db'

export default {
  data() {
    return {
      selectedComment: '',
      comments: [],
      comment: {
        id: '',
        timestamp: '',
        username: '',
        message: ''
      },
      response: '',
      error: false,
    };
  },
  methods: {
    removeComment(){
      removeAliceComment(this.comment.id)
        .then(res => { 
          console.log(res)
          this.error = false
          this.$toast.success('Comment removed successfully');
          this.response = 'Comment removed'
          this.comment.username = ''
          this.comment.id = ''
          this.comment.message = ''
          this.selectedComment = ''
        })
        .catch(res => {
          console.log(res)
          this.error = true
          this.response = res.message
          this.$toast.error('Error removing comment');
        })
    },
    removeAllComments(){
      removeAllAliceComments()
        .then(res => { 
          console.log(res)
          this.error = false
          this.comments = [];
          this.$toast.success('Comments removed successfully');
          this.response = 'Comments removed'
          this.comment.username = ''
          this.comment.id = ''
          this.comment.message = ''
          this.selectedComment = ''
        })
        .catch(res => {
          console.log(res)
          this.error = true
          this.response = res.message
          this.$toast.error('Error removing comments');
        })
    },
    getComments(){
      // fetch comments from firebase db
      readAliceComments(comments => {
        comments = comments.val()
        this.comments = Object.keys(comments).map(key => {
          return Object.assign(comments[key], { id: key })
        })
      })
    },
    getComment(id){
      getAliceComment(id, comment => {
        comment = comment.val()
        
        this.comment = {
          id: id,
          message: comment.message,
          username: comment.username,
          timestamp: comment.timestamp
        }
      })
    }
  },
  beforeMount() {
    this.getComments()
  },
};
</script>